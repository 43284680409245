import {
    faChevronCircleDown,
    faChevronCircleUp,
    faMinusCircle,
    faSort,
    faSortAmountDown,
    faSortAmountDownAlt
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios, {AxiosRequestConfig} from "axios";
import FileDownload from "js-file-download";
import React, {useContext, useEffect, useState} from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    Form,
    Image,
    OverlayTrigger,
    Popover,
    Row,
    Spinner,
    Table
} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import {matchPath, useLocation} from "react-router-dom";
import UserListContext from "../../../context/list/UserListContext";
import translate from "../../../i18n/translate";
import Organisation from "../../../model/organisation/Organisation";
import {csvUploadHostName, frontEndHostName} from "../../../utils/Configuration";
import Pagination from "../../../utils/PaginationHook";
import {hasAnyRole, hasUserRole, Role} from "../../../utils/Security";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import ListRequestContactsModal from './ListRequestContactsModal';
import ListTransferModal from "./ListTransferModal";
import ListFiltersModal, {UserListFilters} from "../ListFiltersModal";
import ListDataQualityModal from "./ListDataQualityModal";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import ListOrganisationJobModal from "../jobs/ListOrganisationJobModal";

class Sort {

    public static readonly TURNOVER = new Sort("financialSummary.turnover", "turnover");
    public static readonly GROWTH = new Sort("financialSummary.threeYearCagr.keyword", "threeYearCagr");
    public static readonly EMPLOYEES = new Sort("numberOfEmployees", "numberOfEmployees");
    public static readonly COMPANY_NAME = new Sort("companyName.keyword", "companyName.keyword");
    public static readonly YEAR_ESTABLISHED = new Sort("yearEstablished", "yearEstablished");

    private constructor(
        public readonly columnName: string,
        public readonly fieldName: string
    ) { }
}

const UserListHome: React.FC = () => {
    const location = useLocation();
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/lists/:listId',
        exact: false,
        strict: false
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingOrganisations, setLoadingOrganisations] = useState<boolean>(false);
    const [organisations, setOrganisations] = useState<Organisation[]>([]);

    const {
        list,
        setList,
        showTransferModal,
        setShowTransferModal,
        showRequestContactsModal,
        setShowRequestContactsModal,
        showDataQualityModal,
        setShowDataQualityModal,
        showJobsModal,
        setShowJobsModal
    } = useContext(UserListContext);
    const [organisationIds, setOrganisationIds] = useState<string[]>(list.organisations);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sort, setSort] = useState<Sort>();
    const [desc, setDesc] = useState<boolean>();
    const [showToolTipForExport, setShowToolTipForExport] = useState<boolean>(false);
    const [isCampaignList, setIsCampaignList] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);


    const formatTurnover = (org: Organisation): string => {
        const UKFormatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0
        });

        const EUFormatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0
        });

        if (org.financialSummary.length === 0 || !org.financialSummary[0].turnover) {
            return "-"
        }

        if (org.countryCode === "UK") {
            return UKFormatter.format(org.financialSummary[0].turnover);
        } else {
            return EUFormatter.format(org.financialSummary[0].turnover);
        }
    };

    const getYearEstablished = (input: string) => {
        if (!input) {
            return "-";
        }

        let date = new Date(input);
        return date.getFullYear();
    };

    const [filters, setFilters] = useState<UserListFilters>({});
    const applyFilters = (f: UserListFilters) => {
        setFilters(f);
        setCurrentPage(1);
        fetchOrganisationIds(f);
    };

    const [loadingOrganisationIds, setLoadingOrganisationIds] = useState<boolean>(false);
    const fetchOrganisationIds = async (filters: UserListFilters) => {
        setLoadingOrganisationIds(true);
        await axios.post(frontEndHostName + "user-lists/" + childMatchedPath?.params.listId + "/organisation-ids",
            filters)
            .then(r => {
                setOrganisationIds(r.data);
            })
            .catch(() => setError(true))
            .finally(() => setLoadingOrganisationIds(false));
    }

    const [error, setError] = useState<boolean>(false);
    const [totalRecords, setTotalRecords] = useState<number>();
    useEffect(() => {
        const fetchOrganisations = async () => {
            setError(false);
            setLoadingOrganisations(true);

            let params: any = {
                page: currentPage - 1,
                size: pageSize
            };

            if (sort) {
                params["sort"] = sort.columnName + "," + (desc ? "desc" : "asc");
            }

            await axios.post(frontEndHostName + "user-lists/" + childMatchedPath?.params.listId + "/organisations",
                filters,
                {
                    params: params
                })
                .then(r => {
                    setOrganisations(r.data.content);
                    setTotalRecords(r.data.totalElements);
                })
                .catch(() => setError(true))
                .finally(() => setLoadingOrganisations(false));
        };

        fetchOrganisations();

    }, [currentPage, list, pageSize, sort, desc, filters]);

    const [selected, setSelected] = useState<string[]>([]);
    const handleSelect = (id: string) => {
        if (isSelected(id)) {
            let newSelection = selected.filter(i => i !== id);
            setSelected(newSelection);
        } else {
            setSelected([
                ...selected,
                id
            ]);
        }
    };

    const isSelected = (id: string) => {
        return selected.indexOf(id) !== -1;
    };

    const selectAll = () => {
        setSelected(organisationIds);
    };

    const deselectAll = () => {
        setSelected([]);
    };

    const [deleting, setDeleting] = useState<boolean>(false);
    const deleteSelected = async () => {
        setDeleting(true);

        await axios.put(frontEndHostName + "user-lists/" + list.id + "/remove-companies", selected)
            .then(r => {
                setList(r.data);
                setOrganisationIds(r.data.organisations);
                deselectAll();
            })
            .catch(error => {
                let responseData = error.response.data;
                if (responseData.errorKey === "campaignlist") {
                    setIsCampaignList(true);
                } else {
                    setShowError(true);
                }
            })
            .finally(() => setDeleting(false));
    };

    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
        }
    };

    const [exportError, setExportError] = useState<boolean>(false);
    const exportSelected = () => {
        let requestConfig = {
            responseType: "blob"
        } as AxiosRequestConfig;

        axios.post(frontEndHostName + "user-lists/export", selected, requestConfig)
            .then(r => {
                let now = new Date().toLocaleDateString();
                let fileName = `${list.title}-${now}.csv`;
                FileDownload(r.data, fileName);
            })
            .catch(() => setExportError(true));
    };

    const CompanyLogo: React.FC<Organisation> = (data: Organisation) => {
        const [src, setSrc] = useState<string>(data.imageUrl ? data.imageUrl : "./icons_company.svg");

        return (
            <Image title={data.companyName}
                style={{
                    height: "30px",
                    width: "30px",
                    objectFit: "cover"
                }}
                src={src}
                onError={() => setSrc("/icons_company.svg")}
                roundedCircle />
        )
    };

    const getGrowthIcon = (growth: number) => {
        if (growth > 0) {
            return (
                <FontAwesomeIcon icon={faChevronCircleUp} style={{ color: "#66d32f", paddingBottom: 2 }} size="sm" />
            )
        } else if (growth < 0) {
            return (
                <FontAwesomeIcon icon={faChevronCircleDown} style={{ color: "red", paddingBottom: 2 }} size="sm" />
            );
        } else {
            return (
                <FontAwesomeIcon icon={faMinusCircle} style={{ color: "#ADADAD", paddingBottom: 2 }} size="sm" />
            );
        }
    };

    const getSortButton = (s: Sort) => {
        if (s === sort) {
            return (
                <a className="sort-button"
                    onClick={() => {
                        setDesc(!desc);
                    }}>
                    <FontAwesomeIcon icon={desc ? faSortAmountDown : faSortAmountDownAlt} />
                </a>
            );
        }

        return (
            <a className="sort-button"
                onClick={() => {
                    setSort(s);
                    setDesc(true);
                }}>
                <FontAwesomeIcon icon={faSort} />
            </a>
        );
    };

    const getOrganisationLink = (organisationId: string) => {
        let link = `/organisation/${organisationId}?list=${childMatchedPath?.params.listId}`;

        if (sort) {
            link += `&sort=${getSort()}`;
        }

        return link;
    };

    const getSort = () => {
        return sort?.fieldName + "," + (desc ? "DESC" : "ASC");
    };

    const popover = (
        <Popover id="popover-basic">
            <h3>{translate("lists.exportPopoverTitle")}</h3>
            <div>
                {translate("lists.exportPopoverMessage")}
            </div>
        </Popover>
    );

    const exportLinkedIn = () => {
        axios.get(csvUploadHostName + "/linkedin/" + list.id)
            .then(r => {
                let fileName = `Linkedin_Campaign_List_${list.id}.csv`;
                FileDownload(r.data, fileName);
            })
            .catch(() => setExportError(true));

    };

    return (
        <div className="user-list-data-container">
            <SweetAlert danger
                show={exportError}
                title={translate("lists.exportfailed")}
                onConfirm={() => setExportError(false)}
                onCancel={() => setExportError(false)}>
                {translate("errors.generic")}
            </SweetAlert>

            {loading ? <IqLoadingIcon /> : (
                <div>
                    <Row>
                        <Col>
                            {hasUserRole(Role.ADMIN) && (
                                <Button variant="primary"
                                    size="sm"
                                    onClick={() => setShowTransferModal(true)}>
                                    {translate("lists.transferlist")}
                                </Button>
                            )}
                            {hasUserRole(Role.ADMIN) && (
                                <Button variant="primary"
                                    style={{ marginLeft: 5 }}
                                    disabled={selected.length === 0}
                                    size="sm"
                                    onClick={() => setShowDataQualityModal(true)}>
                                    {"Data Quality"}
                                </Button>
                            )}
                            {hasAnyRole([Role.ADMIN, Role.DISCOVERY_ONE, Role.DISCOVERY_TWO, Role.DISCOVERY_THREE]) && (
                                <Button variant="primary"
                                    style={{ marginLeft: 5 }}
                                    size="sm"
                                    onClick={() => setShowRequestContactsModal(true)}>
                                    {translate("lists.requestcontacts")}
                                </Button>
                            )}
                            {hasAnyRole([Role.ADMIN, Role.DISCOVERY_ONE, Role.DISCOVERY_TWO, Role.DISCOVERY_THREE]) && (
                                <Button variant="primary"
                                        style={{ marginLeft: 5 }}
                                        size="sm"
                                        onClick={() => setShowJobsModal(true)}>
                                    {translate("lists.requestjobs")}
                                </Button>
                            )}
                        </Col>
                        <Col>
                            <span className="pull-right">
                                <ListFiltersModal applyFilters={applyFilters} />
                                <ButtonGroup size="sm" className="">
                                    {deleting ? (
                                        <Button variant="outline-primary"
                                            size="sm"
                                            disabled>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            &nbsp;{translate("lists.deleting")}
                                        </Button>
                                    ) : (
                                        <Button variant="outline-primary"
                                            disabled={selected.length === 0}
                                            onClick={deleteSelected}
                                            size="sm">
                                            {translate("lists.deleteselected")}
                                        </Button>
                                    )}
                                    <Button variant="primary"
                                            disabled={loadingOrganisationIds}
                                            onClick={selectAll}>
                                        {translate("lists.selectall")}
                                    </Button>
                                    <Button variant="primary"
                                        disabled={selected.length === 0}
                                        onClick={deselectAll}>
                                        {translate("lists.deselectall")}
                                    </Button>
                                </ButtonGroup>
                            </span>
                        </Col>
                        <Col xl={1} xs={1} md={1}>
                            <span className="pull-right">
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" id="export-dropdown" size="sm">
                                        Export
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {hasAnyRole([Role.BASIC_ACCOUNT_MANAGER, Role.ADVANCED_ACCOUNT_MANAGER]) ? (
                                            <OverlayTrigger placement="top" overlay={popover}>
                                                <span>
                                                    <Dropdown.Item disabled>
                                                        {translate("lists.exportselected")}
                                                    </Dropdown.Item>
                                                </span>
                                            </OverlayTrigger>
                                        ) : (
                                            <Dropdown.Item onClick={exportSelected}
                                                           disabled={selected.length === 0}>
                                                {translate("lists.exportselected")}
                                            </Dropdown.Item>
                                        )}
                                        <Dropdown.Item onClick={exportLinkedIn}>
                                            <span className="me-2">{translate("lists.exportlinkedin")}</span>
                                            <span>
                                                <FontAwesomeIcon icon={faLinkedin} />
                                            </span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>
                        </Col>
                    </Row>

                    <div style={{
                        paddingTop: "10px",
                        backgroundColor: "white",
                        borderRadius: "0.45rem",
                        padding: 15,
                        marginTop: 10
                    }}>
                        <Table striped hover size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center">&nbsp;</th>
                                    <th className="text-center">{translate("lists.companynumber")}</th>
                                    <th style={{ width: "35%" }}>
                                        {translate("lists.companyname")}
                                        {getSortButton(Sort.COMPANY_NAME)}
                                    </th>
                                    <th className="text-end">
                                        {translate("lists.established")}
                                        {getSortButton(Sort.YEAR_ESTABLISHED)}
                                    </th>
                                    <th className="text-end">
                                        {translate("lists.employees")}
                                        {getSortButton(Sort.EMPLOYEES)}
                                    </th>
                                    <th className="text-end">
                                        {translate("lists.turnover")}
                                        {getSortButton(Sort.TURNOVER)}
                                    </th>
                                    <th className="text-end">
                                        {translate("lists.growth")}
                                        {getSortButton(Sort.GROWTH)}
                                    </th>
                                    <th>{translate("lists.companytype")}</th>
                                </tr>
                            </thead>
                            {!loadingOrganisations && (
                                <tbody>
                                    {
                                        organisations.map((org: Organisation) => (
                                            <tr key={org.id}
                                                className={isSelected(org.id) ? "row-selected" : ""}
                                                onClick={() => handleSelect(org.id)}>
                                                <td className="text-center">
                                                    <CompanyLogo {...org} />
                                                </td>
                                                <td className="text-center">{org.companyNumber}</td>
                                                <td>
                                                    <a className="iqx-link" href={getOrganisationLink(org.id)}>
                                                        {org.companyName.toUpperCase()}
                                                    </a>
                                                </td>
                                                <td className="text-end">{getYearEstablished(org.yearEstablished)}</td>
                                                <td className="text-end">
                                                    {org.numberOfEmployees ? Number(org.numberOfEmployees).toLocaleString() : "-"}
                                                </td>
                                                <td className="text-end">{formatTurnover(org)}</td>
                                                <td className="text-end">
                                                    {org.threeYearCagr ? (
                                                        <span>{org.threeYearCagr} {getGrowthIcon(org.threeYearCagr)}</span>
                                                    ) : (
                                                        <span>
                                                            -
                                                        </span>
                                                    )}
                                                </td>
                                                <td>{org.primaryType}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            )}
                        </Table>
                        {loadingOrganisations && <IqLoadingIcon />}

                        {error && (
                            <div className="justify-content-md-center">
                                <Alert variant="danger">
                                    {translate("errors.loading")}
                                </Alert>
                            </div>
                        )}

                        <div className="mt-4">
                            {selected.length > 0 && (
                                <span className="pt-2 float-left">
                                    <i className="ps-3">{translate("lists.rowsselected", { count: selected.length })}</i>
                                </span>
                            )}
                            <div className="justify-content-md-end pagination mt-n2">
                                <span className="me-2">
                                    <Form.Control as="select"
                                        style={{ minWidth: "13%" }}
                                        defaultValue={pageSize}
                                        onChange={(event) => changePageSize(event)}
                                        >
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                    </Form.Control>
                                </span>
                                <span>
                                    {totalRecords && (
                                        <Pagination totalRecords={totalRecords}
                                            pageLimit={pageSize}
                                            pageRangeDisplayed={1}
                                            initPage={currentPage}
                                            onChangePage={setCurrentPage}
                                        />
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>

                    <ListTransferModal />
                    {showDataQualityModal && <ListDataQualityModal selected={selected}/>}
                    <ListRequestContactsModal />
                    <ListOrganisationJobModal />
                </div>
            )}
            <SweetAlert danger
                show={showError}
                title={translate("lists.deletecompaniesfailed")}
                onConfirm={() => setShowError(false)}
                onCancel={() => setShowError(false)}>
                {translate("errors.generic")}
            </SweetAlert>
            <SweetAlert danger
                show={isCampaignList}
                title={translate("errors.generic")}
                onConfirm={() => setIsCampaignList(false)}
                onCancel={() => setIsCampaignList(false)}>
                {translate("lists.deletecompaniesfailedcampaignlsit")}
            </SweetAlert>
        </div>
    )
};

export default UserListHome;
