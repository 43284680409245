import {faChevronDown, faFilePdf, faFolder, faPhone} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, {useContext, useEffect, useState} from 'react';
import {Col, Image, Nav, NavItem, OverlayTrigger, Popover, Row} from 'react-bootstrap';
import SweetAlert from "react-bootstrap-sweetalert/dist";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {Link, matchPath, useLocation} from 'react-router-dom';
import I18nContext from '../../context/I18nContext';
import UserOrganisationContext from '../../context/organisation/OrganisationContext';
import SubmenuContext from '../../context/SubmenuContext';
import translate from "../../i18n/translate";
import List from '../../model/list/List';
import Organisation from "../../model/organisation/Organisation";
import {frontEndHostName, organisationHostname, socialHostName} from '../../utils/Configuration';
import AddToListButton from './common/AddToListButton';
import FollowButton from './common/FollowButton';
import FeaturedInLists from "./FeaturedInLists";
import ShareLinkModal from './modals/ShareLinkModal';
import './OrganisationHeader.css';
import {faShareAlt} from "@fortawesome/free-solid-svg-icons/faShareAlt";
import fileDownload from "js-file-download";
import IqXSmallLoadingIcon from "../common/IqXSmallLoadingIcon";

const OrganisationHeader: React.FC = () => {
    const location = useLocation();
    const {lang} = useContext(I18nContext);
    const [showShareLinkModal, setShowShareLinkModal] = useState(false);
    const [showCompanyRequestAlert, setShowCompanyRequestAlert] = useState(false);
    const {submenuItems} = useContext(SubmenuContext);
    const {organisation} = useContext(UserOrganisationContext);
    const [formattedAddress, setFormattedAddress] = useState("");

    interface UpdateAllRequest {
        DirectorImageScrape: boolean,
        UpdateAutoGather: boolean,
        UpdateProfile: boolean,
        UpdateOfficersAndAppointments: boolean,
        UpdateFilingHistory: boolean,
        UpdateFinancials: boolean,
        CaseStudies: boolean,
        WhitePaper: boolean,
        InvestorRelations: boolean,
    }

    interface UpdateCompanyAutomatedRequest {
        DirectorImageScrape: boolean,
        UpdateAutoGather: boolean,
        UpdateProfile: boolean,
        UpdateOfficersAndAppointments: boolean,
        UpdateFilingHistory: boolean,
        UpdateFinancials: boolean,
        CaseStudies: boolean,
        WhitePaper: boolean,
        InvestorRelations: boolean,
    }

    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/organisation/:organisationId',
        exact: false,
        strict: false
    });

    const isActive = (url: string) => {
        if (url.indexOf("public-sector-organisation") !== -1) {
            return location.pathname.indexOf("public-sector-organisation") !== -1;
        }

        return url === location.pathname;
    };

    const [error, setError] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    useEffect(() => {
        let formattedAddress = [];
        if (organisation.addressLine1 != null && organisation.addressLine1.trim() != "") formattedAddress.push(organisation.addressLine1)
        if (organisation.addressLine2 != null && organisation.addressLine2.trim() != "") formattedAddress.push(organisation.addressLine2)
        if (organisation.postTown != null && organisation.postTown.trim() != "") formattedAddress.push(organisation.postTown)
        if (organisation.postCode != null && organisation.postCode.trim() != "") formattedAddress.push(organisation.postCode)

        setFormattedAddress(formattedAddress.join(", "));

    }, [organisation]);

    const [showDelete, setShowDeleteState] = useState<boolean>(false);
    const [deleteFromList, setDeleteFromList] = useState<List | null>(null);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);
    const [showDeleteError, setShowDeleteError] = useState<boolean>(false);

    const removeFromList = async () => {
        if (!deleteFromList) {
            setShowDeleteError(true);
        }
        else {
            axios.put(frontEndHostName + "user-lists/" + deleteFromList.id + "/remove-companies", [childMatchedPath?.params.organisationId])
                .then(() => setShowDeleteSuccess(true))
                .catch(() => setShowDeleteError(true))
                .finally(() => hideDeleteAlert());
        }
    };

    const showDeleteAlert = (list: List) => {
        setDeleteFromList(list);
        setShowDeleteState(true);
    };

    const hideDeleteAlert = () => {
        setDeleteFromList(null);
        setShowDeleteState(false);
    };

    const CompanyLogo: React.FC<Organisation> = (org: Organisation) => {
        const [src, setSrc] = useState<string>(org.imageUrl ? org.imageUrl : "./icons_company.svg");

        return (
            <Image src={src}
                   title={org.companyName}
                   onError={() => setSrc("/icons_company.svg")}/>
        );
    };

    useEffect(() => {
        const fetchTwitterImage = async () => {
            await axios.post(socialHostName + "twitter/profileimg", {
                organisationId: organisation.id,
                imageUrl: organisation.imageUrl
            }).catch(error => console.log(error.message))
        }
        if (organisation && organisation.id && organisation.imageUrl) {
            fetchTwitterImage().then();
        }
    },[organisation])

    const [canRequestResearch, setRequestResearch] = useState<boolean>(false);
    useEffect(() => {
        const fetchPermission = async () => {
            await axios.get(socialHostName + "organisation/" + organisation.id + "/research")
                .then(r => setRequestResearch(r.data))
                .catch(error => console.error(error));
        }

        if (organisation && organisation.id) {
            fetchPermission().then();
        }
    }, [organisation]);

    // Send Automated Update Company Requests
    // Update With Companies House, Profile, Filing History, Officers and Appointments
    const automateCompanyRequests = async() => {
        let updateCompResult: UpdateAllRequest = ({
            DirectorImageScrape: false,
            UpdateAutoGather: false,
            UpdateProfile: false,
            UpdateOfficersAndAppointments: false,
            UpdateFilingHistory: false,
            UpdateFinancials: false,
            CaseStudies: false,
            WhitePaper: false,
            InvestorRelations: false,
        });

        // set values for main object to send to Teams
        let automatedRequestResults: UpdateCompanyAutomatedRequest = ({
            DirectorImageScrape: false,
            UpdateAutoGather: false,
            UpdateProfile: false,
            UpdateOfficersAndAppointments: false,
            UpdateFilingHistory: false,
            UpdateFinancials: false,
            CaseStudies: false,
            WhitePaper: false,
            InvestorRelations: false,
        });

        if (organisation?.id && organisation?.companyUrl) {
            // Experimental Simultaneous Axios
            automatedRequestResults = await axios.all([
                axios.get(organisationHostname + "organisation/" + organisation.id + "/update-all")
                // TODO: Upon Ben's approval for automating financial statements, uncomment the line below along with the UpdateFinancials line in
                //  automatedRequestResults and replace the hardcoded false value.
                //axios.get(organisationHostname + "organisation/" + organisation.id + "/update-financial-statements"),
            ])
                .then(axios.spread((updateAllResponse, updateFinanceResponse) => {
                    updateCompResult = updateAllResponse.data

                    return automatedRequestResults = ({
                        DirectorImageScrape: updateCompResult.DirectorImageScrape,
                        UpdateAutoGather: updateCompResult.UpdateAutoGather,
                        UpdateProfile: updateCompResult.UpdateProfile,
                        UpdateOfficersAndAppointments: updateCompResult.UpdateOfficersAndAppointments,
                        UpdateFilingHistory: updateCompResult.UpdateFilingHistory,
                        //UpdateFinancials: updateFinanceResponse.status >= 200 && updateFinanceResponse.status < 300
                        UpdateFinancials: false,
                        CaseStudies: updateCompResult.CaseStudies,
                        WhitePaper: updateCompResult.WhitePaper,
                        InvestorRelations: updateCompResult.InvestorRelations
                    });
                }))
                .catch(() => {
                    setError(true);
                    return automatedRequestResults;
                });
        }
        return automatedRequestResults;
    }

    // Sends Message to Teams, Add Automated Update Company request afterwards
    const sendSlackMessage = async () => {
        setShowCompanyRequestAlert(true);
        const companyRequestsResults = automateCompanyRequests();
        companyRequestsResults.then(requestResults => {
            // Send Message to Teams
            axios.post(socialHostName +"organisation/research/" + organisation.id, requestResults)
                .catch(() => setError(true));
        });
    }

    const RequestCompanyInfoPopup = () => {
        return (
            <SweetAlert success
                        onConfirm={() => setShowCompanyRequestAlert(false)}
                        title={translate("organisation.updatecompanyinfo.title")}
                        show={showCompanyRequestAlert}>
                {translate("organisation.updatecompanyinfo.text")}
            </SweetAlert>);
    }

    const [exporting, setExporting] = useState<boolean>(false);
    const exportPdf = async () => {
        setExporting(true);
        await axios.get(organisationHostname + "organisation/" + organisation.id + "/export", {
                responseType: 'blob'
            })
            .then(r => {
                let timestamp = new Date().toISOString().split('T')[0];
                //@ts-ignore
                let filename = `${organisation.companyName}_${timestamp}.pdf`.replaceAll(" ", "_");
                fileDownload(r.data, filename);
            })
            .finally(() => setExporting(false));
    }

    const [hasExportPermission, setHasExportPermission] = useState<boolean>(false);
    useEffect(() => {
        const fetchExportPermission = async () => {
            await axios.get(organisationHostname + "organisation/export-capacity")
                .then(r => setHasExportPermission(r.data));
        }

        fetchExportPermission();
    }, [exporting]);

    return (
        <div>
            <SweetAlert warning
                        showCancel
                        confirmBtnText={translate("lists.confirmdelete")}
                        confirmBtnBsStyle="danger"
                        title={translate("lists.deleteconfirmtitle")}
                        onConfirm={() => removeFromList()}
                        onCancel={() => hideDeleteAlert()}
                        show={showDelete}
                        focusCancelBtn>
                {translate("organisation.featuredon.deleteconfirm", { list: deleteFromList?.title })}
            </SweetAlert>

            <SweetAlert success
                        show={showDeleteSuccess}
                        title="Success!"
                        onConfirm={() => setShowDeleteSuccess(false)}
                        onCancel={() => setShowDeleteSuccess(false)}>
                {translate("lists.deletesuccess")}
            </SweetAlert>

            <SweetAlert danger
                        show={showDeleteError}
                        title="Error!"
                        onConfirm={() => setShowDeleteError(false)}
                        onCancel={() => setShowDeleteError(false)}>
                {translate("errors.generic")}
            </SweetAlert>

            <RequestCompanyInfoPopup/>
            <ShareLinkModal show={showShareLinkModal} setShow={setShowShareLinkModal} childMatchedPath={childMatchedPath}/>

            {/* Organisation Main Banner */}
            <Row className="" style={{ marginLeft: 0, marginRight: 10, paddingTop: 15 }}>
                <Col xs={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Row className="" style={{ marginLeft: 0, marginRight: 0, paddingTop: 0 }}>
                        <div id="container-header">
                            <span id="company-summary-container" className="pb-3">

                                <span className="company-logo">
                                    <span className="company-logo-helper">
                                    </span>
                                    <CompanyLogo {...organisation} />
                                </span>
                                <span id="company-summary">
                                    <div id="companyStaus">Status: {organisation.companyStatus}</div>

                                    {/* Company Name, add to list on click */}
                                    <div id="companyName" style={{ fontSize: 24, fontWeight: "bold", maxWidth: "45rem" }}>
                                        {organisation.companyName}
                                    </div>

                                    <div id="companyAddress" className="mb-1" style={{ fontSize: 11 }}  >
                                        {formattedAddress}
                                    </div>
                                    {organisation.phoneNumber && (
                                        <div id="companyPhoneNumber" style={{ fontSize: 11 }}>
                                            <FontAwesomeIcon style={{marginRight: 10}} icon={faPhone} />{organisation.phoneNumber}
                                        </div>
                                    )}
                                    <div  id="companyPrimaryBusinessType" style={{ fontSize: 11 }}>
                                        <FontAwesomeIcon style={{marginRight: 10}} icon={faFolder} />{organisation.primaryType}
                                    </div>
                                </span>
                            </span>

                            <span id="company-actions">

                                <div style={{marginLeft: "auto", paddingTop:"5x", display: "flex", flexDirection: "row", gap: "7px"}} className="mb-1" >
                                    <div>
                                        <OverlayTrigger trigger="click"
                                                        placement="bottom"
                                                        rootClose={true}
                                                        overlay={
                                                            <Popover id="featured-in-lists">
                                                                <FeaturedInLists organisationId={childMatchedPath?.params.organisationId} delete={showDeleteAlert}/>
                                                            </Popover>
                                                        }>

                                            <button type="button" className="iq-action-button white m-1">
                                                <div className="organisation-button-text">
                                                    <span className="text" >{translate("organisation.featuredon.title")}</span>
                                                </div>
                                                <FontAwesomeIcon className="icon" icon={faChevronDown} />
                                            </button>
                                        </OverlayTrigger>
                                    </div>

                                    <div>
                                        <AddToListButton variant={"white"} companyIds={[organisation.id]}/>
                                    </div>

                                    {/* Follow Organisation Button */}
                                    {(organisation && organisation.id) && (
                                        <div>
                                            <FollowButton variant={"white"} companyId={organisation.id} />
                                        </div>
                                    )}
                                </div>

                                <div style={{marginLeft: "auto", paddingTop:"5x", display: "flex", flexDirection: "row", gap: "7px"}} className="mb-1">
                                    <div>
                                        <button onClick={() => { setShowShareLinkModal(true) }} type="button" className="iq-action-button white m-1">
                                            <div className="organisation-button-text">
                                                <span className="text">
                                                    {translate("organisation.temporarylink.share")}
                                                </span>
                                            </div>
                                              <FontAwesomeIcon className="icon" icon={faShareAlt} />
                                        </button>
                                    </div>

                                    {hasExportPermission && (
                                        <div>
                                            <button onClick={exportPdf} disabled={!hasExportPermission} type="button" className="iq-action-button white m-1">
                                                <div className="organisation-button-text">
                                                    <span className="text ">
                                                        Export PDF
                                                    </span>
                                                    <span className="text">
                                                        {exporting ? (
                                                            <div className="ms-3">
                                                                <IqXSmallLoadingIcon />
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <FontAwesomeIcon className="icon" icon={faFilePdf}/>
                                                            </div>
                                                        )}
                                                    </span>
                                                </div>
                                            </button>
                                        </div>
                                    )}

                                    {canRequestResearch && (
                                        <div>
                                            <OverlayTrigger placement="bottom"
                                                            rootClose={true}
                                                            overlay={
                                                                <Popover id="featured-in-lists">
                                                                    {translate("organisation.updatecompanyinfo.popover")}
                                                                </Popover>
                                                            }>
                                                <button onClick={() => sendSlackMessage()} type="button" className="iq-action-button white m-1">
                                                    <div className="organisation-button-text">
                                                    <span className="text" style={lang === "de" || lang === "fr" ? {fontSize: "0.8em"} : {}}>
                                                        {translate("organisation.updatecompanyinfo.title")}
                                                    </span>
                                                    </div>
                                                </button>
                                            </OverlayTrigger>
                                        </div>
                                    )}
                                </div>
                            </span>
                            <div id="container-header-navigation" className="mt-2">
                                <Nav>
                                    {submenuItems.map((item: any, index: any) => {
                                        return (
                                            <NavItem key={index}>
                                                <Link to={item.url} className="nav-item">
                                                    <span>{translate(item.name)}</span>
                                                    {isActive(item.url) && <div className="arrow-wrapper">
                                                        <div className="arrow-up"></div>
                                                    </div>}

                                                </Link>
                                            </NavItem>
                                        )
                                    })}
                                </Nav>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Row>
            <SweetAlert danger
                        show={error}
                        title="An error has occurred!"
                        onConfirm={() => setError(false)}
                        onCancel={() => setError(false)}>
                {translate("errors.generic")}
            </SweetAlert>
            <SweetAlert success
                        show={showSuccess}
                        title="Success!"
                        onConfirm={() => setShowSuccess(false)}
                        onCancel={() => setShowSuccess(false)}>
                Successfully added to list!
            </SweetAlert>
        </div>
    );
};

export default OrganisationHeader;
