import {faPaperPlane, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import ReactTimeAgo from "react-time-ago";
import {DateText, Icon, Item, ItemContent, NotificationItem, Text} from "../Notifications";

const SecFilingsUpdateNotification: React.FC<NotificationItem> = ({ element }) => {
    return (
        <Item read={element.read}>
            <Icon>
                <FontAwesomeIcon color={"white"} icon={faPaperPlane} />
            </Icon>
            <ItemContent>
                <Text>
                    {[<>{element.targets[0].placeholder} SEC filings have been added to </>,
                    <a target={"_blank"} href={["/organisation/", element.middlewares[0].id].join("")}>{element.middlewares[0].placeholder}.</a>,
                    ].reduce((prev: any, curr) =>
                        [...prev, <> </>, curr], [])}
                </Text>
                <DateText>
                    <ReactTimeAgo date={new Date(element.modified)} locale="en-US" />
                </DateText>
            </ItemContent>
        </Item>
    )

}

export default SecFilingsUpdateNotification;