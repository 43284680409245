import React, {useEffect, useState} from "react";
import NewsItem from "../../model/news/NewsItem";
import axios from "axios";
import {organisationHostname, searchHostname, socialHostName} from "../../utils/Configuration";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faSave, faUnlink, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import translate from "../../i18n/translate";
import InvestmentEdit from "../../model/marketintelligence/InvestmentEdit";
import { Select } from "antd";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { format, formatLocale } from "d3-format";
import { SelectOption } from "../news/GlobalNews";
import SearchCompany from "../../model/advanced-search/SearchCompany";
import d3 from "d3";
import SweetAlert from "react-bootstrap-sweetalert";
import FundingDetails from "../../model/organisation/FundingDetails";





interface Props {
    showEditModal: boolean;
    setShowEditModal: (showEditModal: boolean) => void;
    showAddModal: boolean;
    setShowAddModal: (showAddModal: boolean) => void;
    article: FundingDetails
    setArticle: (article: FundingDetails) => void;
    detailsId: number;
    setRefresh: (refresh: boolean) => void;
}

enum CURRENCY {
    USD = "$",
    GBP = "£",
    EUR = "€"
}

const InvestmentEditModal: React.FC<Props> = (props: Props) => {
    
    //const [editError, setEditError] = useState<boolean>(false);
    const [editError, setEditError] = useState<string>(null);
    const [articleError, setArticleError] = useState<boolean>(false);
    const [editLoading, setEditLoading] = useState<boolean>(false);
    const [editData, setEditData] = useState<InvestmentEdit>()
    const [editSuccess, setEditSuccess] = useState<boolean>(false);
    // const [article, setArticle] = useState<FundingDetails>();
    const [rasiedValueFoucs, setRasiedValueFocus] = useState<boolean>(false);
    const [selectedOrganisations, setSelectedOrganisations] = useState<string[]>([]);
    const [organisation, setOrganisation] = useState<string>();
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [aiLoading, setAiLoading] = useState<boolean>(false);

    
    
    useEffect(() => {

        if (props.showAddModal){
            setEditData({...editData, currency: "GBP", amount_raised: 0})
        } else {
            setEditData({
                ...editData,
                amount_raised: props.article.value,
                funding_series: props.article.type,
                currency: props.article.currency,
                organisation: {
                    companyId: props.article.fundedOrganisationId,
                    companyName: props.article.fundedOrganisation.companyName,
                },
                lead_investor: {
                    companyId: props.article.leadingOrganisationId,
                    companyName: props.article.leadingOrganisation,
                },
            })
        }

    }, []);

    const fetchArticle = async (): Promise<NewsItem | null> => {
        return await axios.get(socialHostName + "news/" + props.article.articleUuid).then(r => r.data)
            .catch(() => setArticleError(true))
    };

    const fetchUpdatedFunding = async () => {
        setAiLoading(true);
        let articleData = await fetchArticle();

        console.log(articleData);

        let data: any = {
            articleUUID: props.article.articleUuid
        }

        await axios.post(organisationHostname + "funding-details/ai-information", data)
            .then(r => {
                setEditData(r.data)
                setEditSuccess(true)
            })
            .catch(error => setEditError(error.response.data.title))
            .finally(() => setAiLoading(false));

    };

    const currencyOptions = Object.keys(CURRENCY).map(key => ({
        label: key,
        value: key,
    }));

    const loadOrganisations = (q: string) => {
        let data = {
            searchQuery: q,
            filters: ["Active"]
        };

        return new Promise<SelectOption[]>((resolve: any, reject: any) => {
            axios.post(searchHostname + "quick-search", data)
                .then((r) => {
                    resolve(
                        r.data.map((o: SearchCompany) => {
                            return {
                                label: o.companyName,
                                value: o.companyId
                            }
                        })
                    );
                })
                .catch(reject);
        });
    };

    const customLocale = formatLocale({
        currency: [CURRENCY[editData?.currency], ""],
        decimal: ".",
        thousands: ",",
        grouping: [3]
    });


    const updateOrganisation = (selectedOption) => {
        const updatedEditData = { ...editData };
        updatedEditData.organisation = {
            companyName: selectedOption.label,
            companyId: selectedOption.value,
        };

        setEditData(updatedEditData);
    };

    const updateLeadInvestor = (selectedOption) => {
        const updatedEditData = { ...editData };
        updatedEditData.lead_investor = {
            companyName: selectedOption.label,
            companyId: selectedOption.value,
        };

        setEditData(updatedEditData);
    };

    const updateCurrency = (selectedOption) => {
        const updatedEditData = { ...editData };
        updatedEditData.currency = selectedOption.value;
        setEditData(updatedEditData);
    };

    const handleSave = async () => {

        setSaveLoading(true);

        if(props.showAddModal) {

            console.log(props.article)

            let params: any = {
                articleUuId: props.article?.articleUuid,
            }

            console.log(params)

            await axios.post(organisationHostname + "funding-details/add", editData,{ params }).then( () =>
                {
                    props.setShowAddModal(false)
                    setSaveLoading(false);
                    props.setRefresh(true)
                    props.setArticle(null)
                }
            )
        } else {

            let params: any = {
                articleUUid: props.article?.articleUuid,
                detailsId: props.detailsId
            }

            await axios.post(organisationHostname + "funding-details/save-edit", editData,{ params }).then( () =>
                {
                    props.setShowEditModal(false)
                    setSaveLoading(false);
                    props.setRefresh(true)
                    props.setArticle(null)
                }
            )

        }

    };



    return (
        <Modal show={props.showEditModal || props.showAddModal } onHide={() => {
            props.setShowEditModal(false)
            props.setShowAddModal(false)
            props.setArticle(null)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Investment Edit</Modal.Title>
            </Modal.Header>
            {editLoading ? (
                <div className={"p-5"} style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Spinner className="icon" style={{height: "10rem", width: "10rem"}}
                             animation="border"
                             variant="primary" />
                </div>
            ) : (
                <>
                    <Modal.Body>
                        <div className={"mt-3"}>
                            <div className="organisation-card-label">
                                Funded Organisation
                            </div>
                            <AsyncSelect
                                value={{
                                    label: editData?.organisation?.companyName,
                                    value: editData?.organisation?.companyId
                                }}
                                isLoading={editLoading}
                                onChange={updateOrganisation}
                                loadOptions={loadOrganisations}/>
                            <div className={"pt-2"} style={{fontSize: "80%", color: "dimgrey"}}>Organisation
                                ID: {editData?.organisation?.companyId}</div>
                        </div>
                        <div className={"mt-3"}>
                            <div className="organisation-card-label">
                                Lead Investor Organisation
                            </div>
                            <AsyncSelect
                                value={{
                                    label: editData?.lead_investor?.companyName,
                                    value: editData?.lead_investor?.companyId
                                }}
                                isLoading={editLoading}
                                onChange={updateLeadInvestor}
                                loadOptions={loadOrganisations}/>
                            <div className={"pt-2"} style={{fontSize: "80%", color: "dimgrey"}}>Organisation
                                ID: {editData?.lead_investor?.companyId}</div>
                        </div>
                        {props.showAddModal && (
                            <div style={{display: "flex", flexDirection: "column", gap: "1rem"}} className={"mt-3"}>
                                <div>
                                    <div className="organisation-card-label">Article UUID</div>
                                    <Form.Control name="key"
                                                  placeholder={"Article UUID..."}
                                                  value={props.article?.articleUuid}
                                                  onChange={(e: any) => props.setArticle({
                                                      ...props.article,
                                                      articleUuid: e.target.value})}
                                    />
                                </div>
                            </div>
                        )}
                        <div style={{display: "flex", flexDirection: "column", gap: "1rem"}} className={"mt-3"}>
                            <div>
                                <div className="organisation-card-label">Funding Series</div>
                                <Form.Control name="key"
                                              placeholder={"Funding Series..."}
                                              value={editData?.funding_series}
                                              onChange={(e: any) => setEditData({
                                                  ...editData,
                                                  funding_series: e.target.value
                                              })}

                                />
                            </div>
                        </div>
                        <div className={"mt-3"}>
                            <div style={{display: "flex", flexDirection: "row", gap: "1rem"}} className={"mt-3"}>
                                <div>
                                    <div className="organisation-card-label">Amount Raised</div>
                                    <Form.Control name="key"
                                                  placeholder={"Amount Raised"}
                                                  value={rasiedValueFoucs ? editData?.amount_raised : customLocale.format('$,.3s')(+editData?.amount_raised).replace(/G/, "B")}
                                                  onChange={(e: any) => setEditData({
                                                      ...editData,
                                                      amount_raised: e.target.value
                                                  })}
                                                  onFocus={(e: any) => setRasiedValueFocus(true)}
                                                  onBlur={() => setRasiedValueFocus(false)}

                                    />
                                </div>
                                <div>
                                    <div className="organisation-card-label">Currency</div>
                                    <AsyncSelect
                                        placeholder={"Currency..."}
                                        defaultOptions={currencyOptions}
                                        isClearable
                                        value={{
                                            label: editData?.currency,
                                            value: editData?.currency,
                                        }}
                                        onChange={updateCurrency}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!props.showAddModal &&
                            <button className="iqx-button primary md-size" onClick={fetchUpdatedFunding}>
                                {aiLoading ? <Spinner className="icon" style={{height: "1rem", width: "1rem"}}/> :
                                    <>
                                        AI Update
                                        <FontAwesomeIcon icon={faPlus} className="me-1 ms-2"/>
                                    </>
                                }
                            </button>
                        }
                        <button className="iqx-button primary md-size" onClick={handleSave}>
                            {saveLoading ? <Spinner className="icon" style={{height: "1rem", width: "1rem"}}/> :
                                <>
                                    Save
                                    <FontAwesomeIcon icon={faSave} className="me-1 ms-2"/>
                                </>
                            }
                        </button>
                    </Modal.Footer>
                </>
            )}
            <SweetAlert danger
                        show={editError !== null}
                        title="The AI Gatherer failed"
                        onConfirm={() => setEditError(null)}
                        onCancel={() => setEditError(null)}>
                {editError}
            </SweetAlert>
            <SweetAlert success
                        show={editSuccess}
                        title="Successfully Updated"
                        onConfirm={() => setEditSuccess(false)}
                        onCancel={() => setEditSuccess(false)}>
                {editError}
            </SweetAlert>
        </Modal>

    );
};

export default InvestmentEditModal;